<template>
  <v-row>
    <v-col cols="12" lg="8">
      <v-card flat width="100%">
        <v-card-title class="font-weight-bold d-block">
          <back-button-component/>
          <span class="d-block">Profile information</span>
        </v-card-title>
        <v-card-subtitle>Here you can preview your profile information</v-card-subtitle>
        <v-card-text>
          <v-card>
            <v-card-text class="px-0">
              <v-row>
                <v-col cols="12" lg="5">
                  <v-list-item>
                    <v-list-item-avatar width="70" height="70">
                      <v-img :src="user['personal_data'].avatar ? user['personal_data'].avatar : require(`@/assets/avatar-${user['personal_data'].sex}.jpeg`)"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item class="black--text">
                    <v-list-item-content>
                      <v-list-item-title class="text-body-1 font-weight-bold black--text">
                        {{ user.first_name }} {{ user.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="black--text">
                        {{ user.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" lg="5">
                  <v-list-item class="black--text">
                    <v-list-item-content>
                      <v-list-item-title class="text-body-1 font-weight-bold black--text">
                        Personal information
                      </v-list-item-title>
                      <v-list-item-subtitle class="black--text mt-2">
                        <span class="font-weight-bold">Sex:</span> {{ user['personal_data'].sex }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="black--text mt-2">
                        <span class="font-weight-bold">Registered at:</span> {{ user.date}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-btn color="primary" link text plain :ripple="false"
                         :to="{name: 'dashboard.me.edit'}">Edit profile data</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "User",
  data () {
    return {
      user: {}
    }
  },
  beforeMount() {
    this.$http.get(`${process.env.VUE_APP_API_URL}/auth/me`)
        .then((response) => {
          this.user = response.data['me']
        })
  }
}
</script>

<style scoped>

</style>
